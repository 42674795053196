<template>
  <div>
      <nav class="navbar bg-transparent p-0 pb-2">
        <div class="container-fluid">
            <a class="navbar-brand bars d-flex me-2 mt-2" @click="this.$emit('update:screen', this.screen-1)">
                <em class="ph-arrow-left text-secondary me-2"></em>
            </a>

            <img :src="require('../../assets/img/logo-orange.png')" alt="" style="height: 75px;">                

            <notifications :user="user" :color_notifications_icons="'orange'"/>

            <div class="divider mt-4 d-none"></div>
        </div>
      </nav>

    <div class="body mb-4" style="min-height: auto">
        <div class="container" style="padding: 1rem 2rem 0 2rem">
          <div class="row" v-if="!loading && stocks.length > 0">
            <div class="col-lg-12 col-md-12">
              <h3 class="text-orange">All {{ stock_type }} products</h3>
            </div>          
          </div>

          <div class="row mt-3">
            <span v-if="!loading && stocks.length > 0">
              <div class="col-md-6 col-sm-12 col-lg-6" v-for="({name, description, stock_type, prices, prescription, image_url, in_cart, id}, index) in stocks" :key="index">
                <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa" @click="toStockDetails({name, description, stock_type, prices, image_url, prescription, in_cart, id})">
                  <div class="card-body p-3">
                    <div class="d-flex">
                      <img v-if="image_url != '0' && image_url" :src="axios.defaults.baseURL + '/' + image_url" :alt="name" class="me-4" style="width: 90px; height: 90px; border-radius: 4px;">
                      <img v-if="image_url == '0' || !image_url"  src="https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" :alt="name" class="me-4" style="width: 134.1px; height: 90px; border-radius: 4px;">

                      <div class="row">
                        <h6 class='text-dark fw-bold mt-2 text-capitalize mb-0'> {{ name }} </h6>
                        <small class="text-capitalize">{{ stock_type }}</small>
                        <p class="mt-3 text-capitalize">{{ description.substr(0, 50) }}...</p>  
                      </div>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 text-white fw-bold ps-2 pe-2 rounded-3" style="padding: 2px;" v-if="prescription || in_cart">
                        <font-awesome-icon v-if="prescription" class="text-orange" style="font-size: 12px" :icon="['fas', 'prescription']"></font-awesome-icon>

                        <font-awesome-icon v-if="in_cart" class="text-orange ms-3" style="font-size: 12px" :icon="['fas', 'cart-plus']"></font-awesome-icon>
                      </p>
                                            
                      <p class="ms-auto mb-0 text-white bg-dark fw-bold ps-2 pe-2 rounded-3" style="padding: 2px;">Ksh. {{ prices[0].price }}</p>
                    </div>
                  </div>
                </div>
              </div>    
            </span> 

            <span class="mt-4" v-if="!loading && stocks.length == 0">
              <no-data :message="'No Stock under this Category.'"></no-data>
            </span>

            <span v-if="loading">
              <loading></loading>
            </span>
          </div>
        </div>
    </div>
  </div>    
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_navbar.scss';

    .form-control {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        line-height: 2.1;
    }

    .input-group-text {
        background: transparent;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .scrolling-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        .card {
        display: inline-block;
        }

        &:-webkit-scrollbar-track {
        background-color: #fff;
        }

        &::-webkit-scrollbar {
        background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
        background-color: #fff;
        }
    }

</style>

<script>
  import NoData from '../NoData'
  import Loading from '../Loading'
  import Notifications from '../Notifications'

  export default {
      name: 'FeaturedProducts',
      props: {
        user: {
          required: true,
          type: Object
        },
        screen: {
          required: true,
          type: Number
        },
        stock_type:  {
          required: true,
          type: String
        },
        chosen_stock: {
          required: true,
          type: Object
        }
      },
      components: {
        NoData,
        Loading,
        Notifications
      },
      data() {
        return {
          errors: {},
          search: null,
          stocks: [],
          loading: false
        }
      },
      mounted() {
        this.getStock()
      },
      methods: {
        changeScreen(value) {
          this.$emit('update:screen', value)
        },
        getStock() {
          let url = ''
          if(this.stock_type) {
            url = '/api/stocks/stock_type'
          } else {
            url = '/api/stocks'
          }

          this.loading = true

          this.axios.post(url, {
            stock_type: this.stock_type
          }).then(response => {
            this.stocks = response.data.data
            this.loading = false
          }).catch(errors => {
            console.log(errors);
            this.loading = false
          })
        },
        toStockDetails(value) {
          this.$emit('update:chosen_stock', value)
          this.$emit('update:screen', 3)
        }
    }
}
</script>s