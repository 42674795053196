<template>
    <div>
      <nav :class="'navbar bg-orange-secondary p-0'" style="margin-bottom: 1.5rem; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;">
        <div class="container-fluid">
          <div class="navbar-brand bars p-0">
            <img :src="require('../../assets/img/logo-white.png')" alt="" style="width: 102.75px; height: 75px;">
          </div>
          
          <notifications :user="user"/>

          <div class="divider mt-4 d-none"></div>
        </div>
      </nav>
      
      <floating-nav :page="'Home'"></floating-nav>

      <div class="body mb-4">
        <div class="container" style="padding: 1rem 2rem 0 2rem">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <h2 class="text-dark fw-bold" v-if="user.name">Hi, {{ user.name.split(' ')[0] }}!</h2>
            </div>          
          </div>

          <div class="container">
            <div class="row">
              <status v-model:status_data=status_data></status>
            </div>
          </div>

          <div class="row mb-4 mt-2" id="search-row">
            <div class="input-group">
              <input id="search" type="text" class="form-control border-end-0" v-model="search" placeholder="Search for any medicine here." required>

              <span class="input-group-text pe-4 ps-4" style="cursor: pointer" @click="doSearch()">
                  <font-awesome-icon id="eye" :icon="['fa', 'search']" />                        
              </span>
            </div>
            
            <div class="d-flex">
              <small v-if="errors.search" class="text-burgundy">{{ errors.search }}</small>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="d-flex mb-2">
                <h5 class="text-dark">Categories</h5>
                <h6 class="text-orange text-underline ms-auto" @click="this.$emit('update:screen', 1)">View all</h6>
              </div>

              <div class="scrolling-wrapper">
                <div class="card me-4 mb-4 rounded border-0" v-for="({name, image}, index) in categories" :key="index" style="width: 60%" @click="changeScreen(name)">

                  <div class="card-body bg-transparent d-flex border-bottom-0 p-0" style="border-radius: 10px;">
                    <div class="position-absolute bg-dark h-100 w-100 opacity-25" style="border-radius: 10px"></div>
                    <h6 class="text-white fw-bold mt-2 text-capitalize text-center position-absolute bottom-0 w-100 text-break" style="border-radius: 10px"> {{ name }} </h6>
                    
                    <img :src="require('../../assets/img/item-categories/' + image)" alt="" class="img-fluid mx-auto" style="border-radius: 10px">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-sm-12 mt-2">
              <div class="card bg-orange border-0">
                <div class="span" v-for="({name, description, stock_type, prices, image_url, prescription, in_cart, id}, index) in ad_stocks" :key="index">
                  <div class="card-body" v-if="promo_screen == index">
                    <div class="d-flex">
                      
                      <div class="d-block" @click="toStockDetails({name, description, stock_type, prices, image_url, prescription, in_cart, id})">
                        <h5 class="text-white"> 
                          <font-awesome-icon class="me-2" :icon="['fas', 'info-circle']" /> 
                          {{ name }}
                        </h5>
                        <p class="text-white text-capitalize"> 
                          {{ description.substr(0, 100) ?? '-' }} 
                        </p>
                      </div>

                      <div class="ps-3 pe-3 ms-auto d-flex" @click="nextScreen(index)">
                        <font-awesome-icon class="me-2" :icon="['fas', 'angle-right']" style="margin-top: auto;
                          margin-bottom: auto;
                          color: aliceblue;" />
                      </div>
                    </div>

                    <!-- indicators -->
                    <div class="d-flex">
                      <div class="mx-auto d-flex">
                        <div v-for="i in ad_stocks.length" :key="i" :class="['rounded-circle bg-white me-2', 
                          {'active-indicator': index == i - 1},
                          {'indicator': promo_screen != i - 1}]" />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-sm-12 mt-4">
              <div class="d-flex mb-2">
                <h5 class="text-dark">Featured Products</h5>
              </div>
            </div>
            
            <span v-if="featured_stock.length">
              <div class="col-md-6 col-sm-12 col-lg-6" v-for="({name, prescription, image_url, stock_type, prices, description, in_cart, id}, index) in featured_stock" :key="index">
                <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa" @click="toStockDetails({name, description, stock_type, prices, image_url, prescription, in_cart, id})">
                  <div class="card-body p-3">
                    <div class="d-flex">
                      <img v-if="image_url != '0' && image_url" :src="axios.defaults.baseURL + '/' + image_url" alt="" class="me-4" style="width: 90px; height: 90px; border-radius: 4px;">

                      <img v-if="image_url == '0'  || !image_url" src="https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" alt="" class="me-4" style="width: 134.1px; height: 90px; border-radius: 4px;">

                      <div class="row">
                        <div class="d-flex">  
                          <div class="d-block">
                            <h6 class='text-dark fw-bold mt-2 text-capitalize mb-0'>
                              {{ name }}
                            </h6>
                            <small class="text-capitalize">{{ stock_type }}</small>
                          </div>
                          <font-awesome-icon class="ms-auto text-orange mt-2" :icon="['fa', 'star']"></font-awesome-icon>
                        </div>

                        <p class="mt-3 text-capitalize">{{ description.substr(0, 50) }}...</p>  
                      </div>
                    </div>
                    <div class="d-flex">
                      <p class="mb-0 text-white fw-bold ps-2 pe-2 rounded-3" style="padding: 2px;" v-if="prescription || in_cart">
                        <font-awesome-icon v-if="prescription" class="text-orange" style="font-size: 12px" :icon="['fas', 'prescription']"></font-awesome-icon>

                        <font-awesome-icon v-if="in_cart" class="text-orange ms-3" style="font-size: 12px" :icon="['fas', 'cart-plus']"></font-awesome-icon>
                      </p>

                      <p class="ms-auto mb-0 text-white bg-dark fw-bold ps-2 pe-2 rounded-3" style="padding: 2px;">Ksh. {{ prices[0].price }}</p>
                    </div>                  
                  </div>
                </div>
              </div>
            </span>

            <span class="mt-4" v-if="!featured_stock.length">
              <no-data :message="'No Stock Featured'"></no-data>
            </span>

            <div id="load-more" class="col-md-12 col-sm-12 p-4 pt-1">
              <div class="card bg-transparent bg-gradient border-0">
                <div class="card-body"></div>
              </div>
            </div>          
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss" scoped>
  @import '../../assets/scss/layout/_navbar.scss';
  
  .form-control {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    line-height: 2.1;
  }

  .input-group-text {
    background: transparent;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    .card {
      display: inline-block;
    }

    &:-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
  }

  .indicator {
    width: 6px; height: 6px; opacity: 0.8; margin-top: 1.5px;
  }

  .active-indicator {
    width: 8px; height: 8px;
  }

  .dropdown-toggle::after {
    display: none;
  }
</style>

<script>
  import FloatingNav from '../FloatingNav';
  import Status from '../Status'
  import NoData from '../NoData'
  import Notifications from '../Notifications' 
  
  export default {
      name: 'ChooseStock',
      props: {
        user: {
          required: true,
          type: Object
        },
        screen: {
          required: true,
          type: Number
        },
        stock_type:  {
          required: true,
          type: String
        },
        chosen_stock: {
          required: true,
          type: Object
        },
        search_results :{
          required: true,
          type: Array
        }
      },
      components: {
        FloatingNav,
        Status,
        NoData,
        Notifications
      },
      data() {
        return {
          errors: {},
          search: null,
          promo_screen: 0,
          categories: [
            {
              name: 'Diabetes',
              image: 'diabetes.jpg'
            },
            {
              name: 'Hypertension',
              image: 'hypertension.jpg'
            },
            {
              name: 'Beauty',
              image: 'beauty.jpg'
            },
            {
              name: 'Feminine Care',
              image: 'feminine-care.jpg'
            },            
            {
              name: 'Baby and Kids',
              image: 'baby.jpg'
            },
            {
              name: 'Sexual Wellness',
              image: 'sex-well.jpg'
            },
            {
              name: 'Cough, Cold & Flu',
              image: 'syrup.jpg'
            },
            {
              name: 'Pregnancy',
              image: 'pregnancy.jpg'
            }
          ],
          status_data: {
            "success": false,
            "success_message": 'Form Submission was successful!',
            "error": false,
            "error_message": 'There are some errors.',
            "loading": false
          },     
          temp_prescription_waiting: false     
        }
      },
      computed: {
        featured_stock () {
          return this.$store.state.featured_stock
        },   
        ad_stocks () {
          return this.$store.state.ad_stocks
        },
      },    
      mounted() {
        this.$store.dispatch("getFeaturedStock")
        this.$store.dispatch("getAdStock")
      },      
      methods: {
        changeScreen(value) {
          this.$emit('update:stock_type', value)
          this.$emit('update:screen', 2)
        },
        toStockDetails(value) {
          this.$emit('update:chosen_stock', value)
          this.$emit('update:screen', 3)
        },
        logout() {
          localStorage.removeItem('user')

          setTimeout(() => {
            this.$router.push({name: 'Login'});
          }, 1000);
        },
        doSearch() {
          let data = {
            'search': this.search
          }

          this.axios.post('api/search-stock', data).then(response => {
            this.$emit('update:search_results', response.data.data)
            this.$emit('update:screen', 4)
          }).catch(errors => { 
            this.status_data.loading = false
            this.status_data.error = true
            this.status_data.success = false
            this.status_data.error_message = 'Errors found.'

            if(errors.data) {
              this.errors = errors.data
            } 

            if(errors.message) {
              this.status_data.error_message = errors.message
            }
          })
        },
        requestPrescription() {
          this.axios.post('api/request-prescription').then(()  => {
            this.status_data.loading = false
            this.status_data.error = false
            this.status_data.success = true
            this.status_data.success_message = 'Success! A doctor should reach out in the next day or two.'          
            
          this.temp_prescription_waiting = true
          })
        },
        nextScreen(index) {
          if(this.promo_screen == this.ad_stocks.length - 1) {
            this.promo_screen = 0
          } else {
            this.promo_screen = index + 1
          }
          
        }
      }
  }
</script>