<template>
  <transition name="fade" v-if="loading || screen == null">
    <loading-component></loading-component>
  </transition>

  <transition v-if="!loading" name="fade">
      <div id="wrapper">
        <sidebar :page="'Home'" :sidebar_data=sidebar_data></sidebar>

        <div class="backdrop" @click="close()"></div>
        
        <transition v-if="screen == 0" name="fade">
          <choose-stock :user=user v-model:screen=screen v-model:stock_type=stock_type v-model:chosen_stock=chosen_stock v-model:search_results=search_results></choose-stock>
        </transition>

        <transition v-if="screen == 1" name="fade">
          <all-stock-type :user=user v-model:screen=screen v-model:stock_type=stock_type></all-stock-type>
        </transition>

        <transition v-if="screen == 2" name="fade">
          <all-stock :user=user v-model:screen=screen v-model:stock_type=stock_type v-model:type=type v-model:chosen_stock=chosen_stock></all-stock>
        </transition> 

        <transition v-if="screen == 3" name="fade">
          <stock-details :user=user v-model:screen=screen v-model:chosen_stock=chosen_stock></stock-details>
        </transition>
        
        <transition v-if="screen == 4" name="fade">
          <search-stock v-model:screen=screen v-model:chosen_stock=chosen_stock :stocks=search_results></search-stock>
        </transition>
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  import Sidebar from '../components/AutoSidebar'
  import ChooseStock from '../components/Delivery/ChooseStock'
  import AllStockType from '../components/Delivery/AllStockType'
  import StockDetails from '../components/Delivery/StockDetails'
  import AllStock from '../components/Delivery/AllStock'
  import SearchStock from '../components/Delivery/SearchStock'
  import LoadingComponent from '../components/Loading'

  export default {
    name: 'Home',
    props: {
      prop_screen: {
        required: false,
        type: Number
      }
    },
    components: {
      Sidebar,
      ChooseStock,
      AllStockType,
      StockDetails,
      AllStock,
      SearchStock,
      LoadingComponent
    },   
    data() {
      return {
        sidebar_data: [
          {title: 'Home', link: '', if_children: false, icon: 'home', modal: false},
          {title: 'My Account', link: 'profile', if_children: false, icon: 'user', modal: false},
          {title: 'Learn', link: 'learn', if_children: false, icon: 'user', modal: false},
        ],
        screen: null,
        stock_type: '',
        type: '',
        chosen_stock: {},
        search_results: []
      }
    },  
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }       
    },    
    mounted() {
      this.$store.dispatch("getUser")
    },
    methods: {
      routeTo(value) {
        this.$router.push({name: 'Delivery' + value});
      },
      ifNotLoggedIn() {
        if( !this.user.name) {
          localStorage.removeItem('user')
          this.$router.push({name: 'Login'});
        } else {
          this.screen = 0
        }
      }
    }    
  }
</script>
