<template>
    <div>
      <nav class="navbar bg-transparent p-0 pb-2">
        <div class="container-fluid">
            <a class="navbar-brand bars d-flex me-2 mt-2" @click="this.$emit('update:screen', 0)">
                <em class="ph-arrow-left text-secondary me-2"></em>
            </a>

            <img :src="require('../../assets/img/logo-orange.png')" alt="" style="height: 75px;">                

            <notifications :user="user" :color_notifications_icons="'orange'"/>

            <div class="divider mt-4 d-none"></div>
        </div>
      </nav>
      
      <!-- <floating-nav :page="'Home'"></floating-nav> -->

      <div class="body mb-4" style="min-height: auto">
        <div class="card border-0 w-100">
          <div class="card-header bg-white ps-4 pe-4 pb-0 border-0">  
            <div class="d-flex mb-4 ps-3 pe-3">
              <div class="d block">
                <h4 class="text-orange mt-2 mb-0 text-capitalize">{{ chosen_stock.name }}</h4>
                <small class="text-muted text-capitalize">{{ chosen_stock.stock_type }}</small>
              </div>  
            </div>
            <div class="row ps-4 pe-4">
              <img v-if="chosen_stock.image_url != '0' && chosen_stock.image_url" :src="axios.defaults.baseURL + '/' + chosen_stock.image_url" alt="" class="img-fluid p-0" style="border: 1px solid #eee; border-radius: 4px;">

              <img v-if="chosen_stock.image_url == '0' || !chosen_stock.image_url"  src="https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" alt="" class="img-fluid p-0" style="border: 1px solid #eee; border-radius: 4px">
            </div>

            <div class="d-flex mt-3 mb-3" v-if=" !status.loading && !status.success">
              <div class="btn-group ms-auto ps-3 pe-3">

                <div class="btn btn-sm btn-dark" @click="remove()">
                  <font-awesome-icon :icon="['fas', 'minus']" style="font-size: 12px"></font-awesome-icon>
                </div>
                
                <div class="btn btn-sm btn-dark">
                  {{ quantity }}
                </div>
                
                <div class="btn btn-sm btn-dark" @click="add()">
                  <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 12px"></font-awesome-icon>
                </div>
              </div>
            </div>             
              <!-- <h4 class="ms-auto">
                <i class="ph-bookmark-simple mt-2"></i>
              </h4> -->
          </div>
          
          <div class="card-body border-0 p-4 pt-0">                        
            <p class="text-dark ps-2 pe-2 fs-6 mt-3 text-capitalize">{{ chosen_stock.description }}</p>

            <p class="text-muted ms-2" v-if="chosen_stock.prescription">&bull; Requires Prescription </p>    

            <div class="d-flex">
              <button class="btn bg-orange text-white w-80 btn-large btn-lg ps-4 pe-4 mt-3 mx-auto" style="border-radius: 25px;" @click="addToCart()">

                <p class="mb-0" style="font-size: 1.1rem" v-if="!status.success 
                    && ( !chosen_stock.in_cart || chosen_stock.in_cart == false)">
                  Add to Cart <span class="ms-3 me-3">|</span> Ksh. {{ chosen_stock.prices[0].price * quantity }}
                </p>

                <div class="d-flex" v-if="status.loading">
                  <p class="mb-0" style="font-size: 1.1rem" >
                    Processing 
                  </p> 

                  <div class="spinner-border text-light spinner-border-sm ms-3 mt-1" role="status" style="font-size: 1.4rem">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>

                <p class="mb-0" style="font-size: 1.1rem" v-if=" (!status.loading && status.success) || chosen_stock.in_cart == true">
                  Added to Cart 
                  <font-awesome-icon class="ms-2" :icon="['fas', 'check-circle']"></font-awesome-icon>
                </p>

              </button>
            </div>
            <div class="d-flex">
              <router-link :to="{name: 'Cart'}" class="btn bg-default text-white w-80 btn-xs ps-4 pe-4 mt-3 mx-auto" style="border-radius: 25px;">
                To Checkout <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_navbar.scss';

    .form-control {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        line-height: 2.1;
    }

    .scrolling-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        .card {
          display: inline-block;
        }

        &:-webkit-scrollbar-track {
          background-color: #fff;
        }

        &::-webkit-scrollbar {
          background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #fff;
        }
    }

</style>

<script>
  import Notifications from '../Notifications' 

  export default {
      name: 'StockDetails',
      components: {
        Notifications
      },
      props: {
        user: {
          required: true,
          type: Object
        },
        screen: {
          required: true,
          type: Number
        },
        chosen_stock:  {
          required: true,
          type: Object
        },
      },
      data() {
        return {
          errors: {},
          quantity: 1,
          status: {
            loading: false,
            success: false,
            error: false
          }
        }
      },
      methods: {
        changeScreen(value) {
          this.$emit('update:screen', value)
        },
        addToCart() {
          let data = {
            stock_id: this.chosen_stock.id,
            quantity: this.quantity
          }
          this.axios.post('api/cart', data).then(() => {
            this.status.loading = false,
            this.status.success = true
          }).catch(errors => {
            this.status.loading = false,
            this.status.error = true
            console.log(errors);
          })
        },
        add() {
          this.quantity++
        },
        remove() {
          if(this.quantity > 1) {
            this.quantity--
          }
        }        
    }
}
</script>