<template>
  <div>
      <nav class="navbar bg-transparent">
        <div class="container-fluid">
            <a class="navbar-brand bars d-flex" @click="this.$emit('update:screen', 0)">
                <i class="ph-arrow-left text-secondary me-2"></i>
            </a>

            <span class="navbar-left">
                <div class="navbar-brand">
                    <span style="display: -webkit-inline-box;">
                        <h4 style="margin-top: 0.2rem">
                        <i class="ph-bell-ringing text-white me-3 nav-icon circle p-2 bg-default"></i>
                        </h4>
                    </span>
                </div>      
            </span>

            <div class="divider mt-4 d-none"></div>
        </div>
      </nav>

    <div class="body mb-4" style="min-height: auto">
        <div class="container" style="padding: 1rem 2rem 0 2rem">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <h3 class="text-orange">All results</h3>
            </div>          
          </div>

          <div class="row mt-3">
            <span v-if="!loading && stocks.length > 0">
              <div class="col-md-6 col-sm-12 col-lg-6" v-for="({name, description, stock_type, prices, prescription, image_url, id}, index) in stocks" :key="index">
                <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa" @click="toStockDetails({name, description, stock_type, prices, image_url, prescription, id})">
                  <div class="card-body p-3">
                    <div class="d-flex">
                      <img v-if="image_url != '0' && image_url" :src="axios.defaults.baseURL + '/' + image_url" :alt="name" class="me-4" style="width: 90px; height: 90px; border-radius: 4px;">

                      <img v-if="image_url == '0'  || !image_url" src="https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" :alt="name" class="me-4" style="width: 134.1px; height: 90px; border-radius: 4px;">

                      <div class="row">
                        <h6 class='text-dark fw-bold mt-2 text-capitalize mb-0'> {{ name }} </h6>
                        <small>{{ stock_type }}</small>
                        <p class="mt-3">{{ description.substr(0, 50) }}...</p>  
                      </div>
                    </div>
                    <div class="d-flex">
                      <p class="ms-auto mb-0 text-white bg-dark fw-bold ps-2 pe-2 rounded-3" style="padding: 2px;">Ksh. {{ prices[0].price }}</p>
                    </div>
                  </div>
                </div>
              </div>    
            </span> 

            <span class="mt-4" v-if="!loading && stocks.length == 0">
              <no-data :message="'No Stock under this Category.'"></no-data>
            </span>

            <span v-if="loading">
              <loading></loading>
            </span>
          </div>
        </div>
    </div>
  </div>    
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_navbar.scss';

    .form-control {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        line-height: 2.1;
    }

    .input-group-text {
        background: transparent;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .scrolling-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        .card {
        display: inline-block;
        }

        &:-webkit-scrollbar-track {
        background-color: #fff;
        }

        &::-webkit-scrollbar {
        background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
        background-color: #fff;
        }
    }

</style>

<script>
  import NoData from '../NoData'
  import Loading from '../Loading'

  export default {
      name: 'SearchStock',
      props: {
        screen: {
            required: true,
            type: Number
        },
        chosen_stock: {
            required: true,
            type: Object
        },
        stocks: {
            required: true,
            type: Array
        }
      },
      components: {
        NoData,
        Loading
      },
      data() {
        return {
          errors: {},
          search: null,
        }
      },
      methods: {
        changeScreen(value) {
          this.$emit('update:screen', value)
        },
        toStockDetails(value) {
          this.$emit('update:chosen_stock', value)
          this.$emit('update:screen', 3)
        }
    }
}
</script>s