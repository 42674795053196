<template>
    <div>
        <nav class="navbar bg-transparent p-0 pb-2">
            <div class="container-fluid">
                <a class="navbar-brand bars d-flex me-2 mt-2" @click="this.$emit('update:screen', this.screen-1)">
                    <em class="ph-arrow-left text-secondary me-2"></em>
                </a>

                <img :src="require('../../assets/img/logo-orange.png')" alt="" style="height: 75px;">                

                <notifications :user="user" :color_notifications_icons="'orange'"/>

                <div class="divider mt-4 d-none"></div>
            </div>
        </nav>

        <div class="body mb-4" style="min-height: auto">
            <div class="container" style="padding: 1rem 2rem 0 2rem">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <h3 class="text-orange">All Categories</h3>
                  </div>          
                </div>

                <div class="row mt-3">                
                    <div class="col-md-6 col-sm-12 col-lg-6" v-for="({name, image, description}, index) in categories" :key="index">
                        <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa" @click="changeScreen(name)">
                        <div class="card-body p-3">
                            <div class="d-flex">
                                <img :src="require('../../assets/img/item-categories/' + image)" :alt="image" class="img-fluid me-4" style="width: 90px; height: 90px;">
                                <div class="row">
                                    <h6 class='text-dark fw-bold mt-2 text-capitalize'> {{ name }} </h6>
                                    <p>{{ description }}</p>  
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>          
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_navbar.scss';

    .form-control {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        line-height: 2.1;
    }

    .input-group-text {
        background: transparent;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .scrolling-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        .card {
        display: inline-block;
        }

        &:-webkit-scrollbar-track {
        background-color: #fff;
        }

        &::-webkit-scrollbar {
        background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
        background-color: #fff;
        }
    }


</style>

<script>
    import Notifications from '../Notifications';

    export default {
        name: 'AllStockType',   
        props: {
            user: {
                required: true,
                type: Object
            },
            screen: {
                required: true,
                type: Number
            },
            stock_type:  {
                required: true,
                type: String
            },
        },
        components: {
            Notifications
        },  
        data() {
            return {
                categories: [
                    {
                        name: 'Diabetes',
                        image: 'diabetes.jpg',
                        description: 'Medicines and devices for diabetes'
                    },
                    {
                        name: 'Hypertension',
                        image: 'hypertension.jpg',
                        description: 'Medicines and devices for blood pressure'
                    },                      
                    {
                        name: 'Beauty',
                        image: 'beauty.jpg',
                        description: 'Hair, Skin Care, Makeup, Fragrance and more.'
                    },
                    {
                        name: 'Feminine Care',
                        image: 'feminine-care.jpg',
                        description: 'Menstrual care like pads, tampons and others'
                    },
                    {
                        name: 'Baby and Kids',
                        image: 'baby.jpg',
                        description: 'Diapers, wipes, formula and more' 
                    },
                    {
                        name: 'Medicines & treatment',
                        image: 'syrup.jpg',
                        description: 'Prescription medicine for diabetes, hypertension, Medical oxygen and others'
                    },                    
                    {
                        name: 'Sexual Wellness',
                        image: 'sex-well.jpg',
                        description: 'Contraception, condoms, lubes, enhancers and more'
                    },
                    {
                        name: 'Cough, Cold & Flu',
                        image: 'syrup.jpg',
                        description: 'Syrups, vitamins, supplements, cough drops and others'
                    },
                    {
                        name: 'Pregnancy',
                        image: 'pregnancy.jpg',
                        description: 'Supplements, home tests, ante-natal tests and more'
                    },
                    {
                        name: 'Allergies',
                        image: 'allergies.jpg',
                        description: 'Sinus medicine, asthma medicine, steroid creams and tablets and more'
                    },
                    {
                        name: 'Diet and Nutrition',
                        image: 'digestive-health.jpg',
                        description: 'Heartburn medicine, weight loss, fitness supplements and others'
                    },
                    {
                        name: 'Eye Care',
                        image: 'eye-care.jpg',
                        description: 'Eye drops, prescription glasses and sunglasses'
                    },
                    {
                        name: 'Home Tests',
                        image: 'home-tests.jpg',
                        description: 'Pregnancy tests, COVID tests, HIV tests,  and others'
                    },
                    {
                        name: 'Medical Devices',
                        image: 'medical-devices.jpg',
                        description: 'Blood pressure, Pulse Oximeter, Oxygen concentrator and others'
                    },
                    {
                        name: 'Pain Relief',
                        image: 'pain-relief.jpg',
                        description: 'Medicines for menstrual pain, backaches, headaches, stomach-aches and more'
                    },
                    {
                        name: 'Skin Care',
                        image: 'skin-care.jpg',
                        description: 'Medicines and creams for rashes, acne, aging, dryness and more'
                    }, 
                    {
                        name: 'First Aid',
                        image: 'first-aid.jpg',
                        description: 'Band-aids, first aid kits and basic life support training and others'
                    },                                                                              
                ]                
            }
        },
        methods: {
            changeScreen(value) {
                this.$emit('update:stock_type', value)
                this.$emit('update:screen', 2)
            },
        }
    }
</script>